<template>
  <div class="mt-down-header mb-30">
    <!-- breadcrumb -->
    <base-breadcrumb :crumbs="items" class="c-m-r c-m-l" />
    <!-- breadcrumb end -->
    <!-- strategy info -->
    <div class="strategy-info c-border flex-between c-m-r c-m-l">
      <div class>
        <h1>{{ strategyData.strategy_name }}</h1>
        <p class="flex-common">
          <img src="@/assets/img/icon-desc@2x.png" alt height="30" width="40">
          {{ strategyData.strategy_introduction }}
        </p>
        <div class="info-field flex-common mt-30" style="flex-wrap: wrap">
          <p>
            <span>创建日期：</span>
            {{ strategyData.as_of_date }}
          </p>
          <!-- <p>
            <span>实盘天数：</span>
            {{strategyData.measure_days}}
          </p>-->
          <p>
            <span>调仓时点：</span>
            {{ strategyData.adjusting_the_position }}
          </p>
          <p>
            <span>调仓周期：</span>
            {{ strategyData.adjustment_cycle }}
          </p>
          <!-- <p>
            <span>实时策略：</span>
            {{strategyData.real_strategy}}
          </p>-->
          <p>
            <span>持仓股票：</span>
            {{ strategyData.position_stock }}
          </p>
          <!-- <p>
            <span>大盘择时：</span>
            {{strategyData.benchmark}}
          </p>-->
          <p>
            <span>运行时间：</span>
            {{ strategyData.backtest_date }}
          </p>
          <!-- <p>
            <span>上次调仓</span>
            {{strategyData.last_day}}
          </p>-->
          <p>
            <span>下次调仓：</span>
            {{ strategyData.next_day }}
          </p>
        </div>
      </div>
      <div class="info-right">
        <p>
          <span class="rise-red strategy-price">VIP免费订阅</span>
        </p>
        <p>
          <span>适合资金：</span>
          {{ strategyData.cash }}
        </p>
        <div v-if="loginStatus">
          <button v-if="!isvip&&exptime>0" @click="pay">支付页面</button>
          <!-- "selectStrategy($route.params.categoryId, $route.params.strategyId, strategyData.strategy_name)" -->
          <button v-if="isvip||exptime<=0" @click="selectStrategy">订阅</button>
        </div>
        <!-- <button v-else disable="disable" style="background:#DDDDDD;outline:0">支付页面</button> -->
        <!-- <p class="flex-common share-icon mt-30">
          分享：
          <img class="weixin" src="@/assets/img/weixin@2x.png" alt>
          <img class="weibo" src="@/assets/img/weibo@2x.png" alt @click="shareToWeiBo">
        </p>-->
      </div>
    </div>
    <!-- strategy info end -->

    <!-- transaction show -->
    <div class="transaction-show c-border c-m-r c-m-l mt-30">
      <div class="index-border-bottom my-card-title">实盘结果</div>
      <div class="strategy-list pb-0">
        <div class="row m-lr">
          <div v-if="Object.keys(strategyData).length !== 0" class="content-index col-3 p-lr">
            <div class="main-index flex-center-column">
              <p>累积收益率</p>
              <span
                :class="Number.parseFloat(strategyData.total_return_value) < 0 ? 'fall-green' : 'rise-red'"
              >{{ strategyData.total_return_value }}</span>
            </div>
            <div class="index-border-bottom index-border-top mb-15">
              <div class="another-index flex-common mt-15">
                <div class="flex-center-column index-border-right">
                  <p
                    :class="Number.parseFloat(strategyData.daily_return_value) <0 ? 'fall-green' : 'rise-red'"
                  >{{ strategyData.daily_return_value }}</p>
                  <span>日收益率</span>
                </div>
                <div class="flex-center-column">
                  <p
                    :class="Number.parseFloat(strategyData.annualized_return_value) < 0 ? 'fall-green' : 'rise-red'"
                  >{{ strategyData.annualized_return_value }}</p>
                  <span>年化收益率</span>
                </div>
              </div>
              <div class="another-index flex-common index-border-top">
                <div class="flex-center-column index-border-right">
                  <p class="other-index-color">{{ strategyData.annualized_volatility_value }}</p>
                  <span>年化波动率</span>
                </div>
                <div class="flex-center-column">
                  <p class="other-index-color">{{ strategyData.maximum_drawdown_value }}</p>
                  <span>最大回撤</span>
                </div>
              </div>
              <div class="another-index flex-common index-border-top mb-15">
                <div class="flex-center-column index-border-right">
                  <p class="other-index-color">{{ strategyData.sharp_value }}</p>
                  <span>夏普比率</span>
                </div>
                <div class="flex-center-column">
                  <p class="other-index-color">{{ strategyData.measure_days }}</p>
                  <span>实盘天数</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9 p-lr chart-area">
            <Chart :option="chartOption" class="chart" style="height:500px;" />
            <ul class="strategy-time flex-common">
              <p>缩放</p>
              <li
                v-for="(item, index) in options"
                :key="index"
                :class="{active: strategyTime == item.value}"
                @click="qureyByTime(item.value)"
              >{{ item.text }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- transaction show end -->

    <!-- transaction record -->
    <div class="transcation-record c-border mt-30 c-m-l c-m-r">
      <h1 class="my-card-title index-border-bottom">策略持仓</h1>
      <b-tabs class="mt-30 tab-card">
        <b-tab title="历史持仓" @click="holdRecord">
          <div class="m-lr-30 mt-30 mb-30 c-border">
            <b-table :fields="holdFields" :items="holdRecordItems" hover>
              <!-- 当日盈亏比 -->
              <template
                v-slot:cell(Today_Profit_and_Loss)="data"
              >
                {{ data.item.Today_Profit_and_Loss }} / {{ data.item.Today_profit }}
              </template>
              <!-- 累积盈亏比 -->
              <template
                v-slot:cell(Total_Profit_and_Loss)="data"
              >{{ data.item.Total_Profit_and_Loss }} / {{ data.item.Total_Profit }}</template>
            </b-table>
            <p
              v-if="holdRecordItems.length === 0"
              style="font-size:14px;text-align: center; color:#666"
            >暂时没有数据</p>
          </div>
          <div class="date-picker">
            <span>选择日期：</span>
            <flat-pickr
              v-model="tdates"
              :config="{
                defaultDate: new Date().setDate(new Date().getDate()),
                disable: [function(date) {return (date.getDay() === 0 || date.getDay() === 6)}]
              }"
              @on-change="isTradingDay"
            />
          </div>
        </b-tab>
        <b-tab title="历史调仓" @click="changeHoldRecord">
          <div class="m-lr-30 mt-30 mb-30 c-border">
            <b-table :fields="changeHoldFields" :items="changeHoldRecordItems" hover />
          </div>
          <div class="date-picker">
            <span>选择日期：</span>
            <flat-pickr
              v-model="tdate"
              name="date"
              :config="{
                defaultDate: new Date().setDate(new Date().getDate() -1),
                disable: [
                  function(date) {
                    return (date.getDay() === 0 || date.getDay() === 6)
                  }
                ]
              }"
              @on-change="changeHoldRecordByDate"
            />
          </div>
        </b-tab>
      </b-tabs>
      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="recordData.total_records"
        class="m-lr-30 mb-30"
        @change="qureyByPage"
      ></b-pagination>-->
    </div>
    <!-- transaction record end -->
  </div>
</template>

<script>
import '../../assets/style/common.scss'
import Chart from '../../components/Chart'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { mapState } from 'vuex'
// import { vipApi } from '../../api/login'
import Vue from 'vue'
import { Dialog } from 'element-ui'
import { Message } from 'element-ui'

Vue.use(Dialog)
export default {
  components: {
    Chart,
    flatPickr
  },

  data() {
    return {
      isvip: localStorage.getItem('isvip'),
      // 面包屑导航
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '策略商城',
          route: '/strategy-list'
        },
        {
          text: '策略详情',
          route: ''
        }
      ],
      // 策略数据
      strategyData: {},
      // 图表
      chartOption: {},

      // 历史持仓
      holdFields: [
        { key: 'As_Of_Date', label: '日期' },
        { key: 'SecShortName', label: '股票名称' },
        { key: 'Industry', label: '所属行业' },
        { key: 'Cost_Price', label: '买入价格' },
        { key: 'Close_Value', label: '最近收盘价' },
        { key: 'Today_Profit_and_Loss', label: '当日盈亏(百分比/金额)' },
        { key: 'Total_Profit_and_Loss', label: '累积盈亏(百分比/金额)' }
      ],
      // daily-profit-and-loss  total-profit-and-loss

      holdRecordItems: [],

      // 历史调仓
      changeHoldFields: [
        { key: 'As_Of_Date', label: '日期' },
        { key: 'SecShortName', label: '股票' },
        { key: 'Transaction_class', label: '操作' },
        { key: 'Cost_Amount', label: '数量' },
        { key: 'Cost_Price', label: '成交均价' }
      ],

      changeHoldRecordItems: [],

      // strategy time
      options: [
        { value: '4', text: '1个月' },
        { value: '3', text: '3个月' },
        { value: '2', text: '6个月' },
        { value: '1', text: '1年' },
        { value: '5', text: '3年' },
        { value: '9', text: '全部' }
      ],

      strategyTime: 1,
      // 试用期判断
      exptime: '',

      // 日期控件
      tdate: new Date(),
      tdates: new Date()
    }
  },

  computed: {
    ...mapState({
      loginStatus: state => state.login.loginStatus
    })
  },

  mounted() {
    this.holdRecord()
    this.fetchStrategyData()
    // 是否是vip
    if (this.isvip === '1') {
      this.isvip = true
    } else {
      this.isvip = false
    }
    // 判断是否是试用期内用户
    const exptime = localStorage.getItem('exptime')
    this.exptime = exptime
    // console.log(this.exptime)
  },

  methods: {
    // 判断是否是vip
    // async vip() {
    //   const username = localStorage.getItem('username')
    //   const res = await vipApi(username)
    //   // console.log(res)
    //   if (res.data.isvip === '1') {
    //     this.isvip = true
    //   } else {
    //     this.isvip = false
    //   }
    //   console.log(this.isvip)
    //   console.log(!this.isvip)
    // },
    // 获取策略数据
    fetchStrategyData: function() {
      // strategy_id
      this.strategyId = this.$route.params.strategyId
      // console.log(this.strategyId)
      // class_id
      this.categoryId = this.$route.params.categoryId
      // console.log(this.categoryId)
      const apiurl =
        '/api/office_web/strategy_chart/?strategy_number=' +
        this.strategyId +
        '&show_days=1' +
        '&category_group=' +
        this.categoryId
      // const apiurl =
      //   '/api/office_web/strategy_chart/?category_group=' +
      //   this.categoryId +
      //   '&show_days=1' +
      //   '&strategy_number=' +
      //   this.strategyId

      // 暂时写死的策略二
      this.http.get(apiurl).then(res => {
        this.strategyData = res.data
        // console.log('dataddd:', this.strategyData)
        // this.chartdata = res.data.data;
        this.setChartOpition(this.strategyData.data)
      })
      //  路由取参数
      // 页面挂载后获取数据和制图 制图
    },

    // 按时间查策略chart数据
    qureyByTime: function(value) {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId

      const apiurl =
        '/api/office_web/strategy_chart/?strategy_number=' +
        this.strategyId +
        '&show_days=' +
        value +
        '&category_group=' +
        this.categoryId

      this.http.get(apiurl).then(res => {
        this.setChartOpition(res.data.data)
      })
      this.strategyTime = value
    },

    // 解析chart数据
    setChartOpition: function(data) {
      this.chartOption = {
        // 指定图表的配置项和数据

        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['中证500', '策略'],
          right: 80
        },
        dataZoom: [
          {
            startValue: ''
          },
          {
            type: 'inside'
          }
        ],
        xAxis: {
          data: data.map(function(item) {
            return item.as_of_date
          }),
          splitLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          name: '累计收益率'
        },
        series: [
          {
            name: '中证500',
            type: 'line',
            itemStyle: {
              color: 'rgb(29, 32, 136)'
            },
            data: data.map(function(item) {
              return item.market
            }),
            splitLine: {
              show: true,
              lineStyle: {
                color: ['grey'],
                width: 1,
                type: 'solid'
              }
            },
            areaStyle: {
              // color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
            }
          },
          {
            name: '策略',
            type: 'line',
            itemStyle: {
              color: 'rgb(255, 54, 0)'
            },
            data: data.map(function(item) {
              return item.predict_strategy_value
            }),
            areaStyle: {
              // color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
            }
          }
        ]
      }
      // console.log('chartOption:',this.chartOption);
      // console.log('chartOption[i]:',this.chartOption[i]);
    },

    // 历史持仓table
    holdRecord: function() {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `/api/office_web/new_transaction_record/?strategy_number=${this.strategyId}&category_group=${this.categoryId}`
      this.http.get(recordApi).then(res => {
        // console.log(res)
        this.recordData = res.data
        this.holdRecordItems = res.data.transaction_records
      })
    },

    // 按日期查历史持仓记录
    holdRecordByDate: function(value) {
      this.$nextTick(function() {
        this.strategyId = this.$route.params.strategyId
        this.categoryId = this.$route.params.categoryId
        const recordApi = `/api/office_web/new_transaction_record/?strategy_number=${this.strategyId}&category_group=${this.categoryId}&date=${value}`
        this.http.get(recordApi).then(res => {
          this.recordData = res.data
          this.holdRecordItems = res.data.transaction_records
        })
      })
    },

    // 历史调仓table
    changeHoldRecord: function() {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `/api/office_web/adjust_record/?strategy_number=${this.strategyId}&category_group=${this.categoryId}`
      this.http.get(recordApi).then(res => {
        this.recordData = res.data
        this.changeHoldRecordItems = res.data.adjust_records
      })
    },

    // 按日期查历史调仓记录
    changeHoldRecordByDate: function(date, value) {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `/api/office_web/adjust_record/?strategy_number=${this.strategyId}&category_group=${this.categoryId}&date=${value}`
      this.http.get(recordApi).then(res => {
        this.recordData = res.data
        this.changeHoldRecordItems = res.data.adjust_records
      })
    },
    // 分页查询
    qureyByPage: function(page) {
      const recordApi = ``
      this.http.get(recordApi).then(res => {
        // this.recordData = res.data;
        this.recordItems = res.data.transaction_records
      })
    },

    // 分享功能
    shareToWeiBo: function(e) {
      e.preventDefault()
      const _url = 'https://www.ainvestcn.com'
      let _shareUrl =
        'http://v.t.sina.com.cn/share/share.php?title="2853131498"'
      _shareUrl += '&url' + encodeURIComponent(_url || document.location)
      window.open(_shareUrl, '_blank')
    },
    // 支付页面
    pay() {
      this.$router.push({ name: 'pay' })
    },
    // 订阅策略
    selectStrategy: function() {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const strategyName = this.strategyData.strategy_name
      console.log(this.strategyData.strategy_name)
      console.log('lead-login', this.loginStatus)
      if (this.loginStatus) {
        const data = {
          new_strategy_id: this.strategyId,
          new_class_id: this.categoryId
        }
        if (confirm('确定使用新的策略吗？')) {
          this.http
            .post('/api/office_web/update_personal_strategy/', data)
            .then(res => {
              // console.log('res', res)
              if (res.data.data === 'success') {
                setTimeout(
                  this.$router.push({ path: '/user-account' }),
                  2000
                )
                setTimeout(
                  alert(`正在使用"${strategyName}"策略，\n点击【停止状态】按钮开始交易！`),
                  2000
                )
                // console.log('res', res)
              } else {
                Message.warning('更改失败，请联系管理员！')
              }
            })
        }
      } else {
        // alert('需登陆后才能订阅策略，如无账号可联系客服开通！')
        // if (confirm('需要登陆')) {
        //   // this.$router.push({ path: '/login' })
        // }

        Message.warning('未登录')
        // 加 redirect
        this.$router.push({ path: '/login' })
      }
    },

    /**
     * 判断是否交易日
     */
    isTradingDay: function(date, value) {
      // const day1 = new Date()
      // day1.setDate(day1.getDate() - 1)
      // var today = day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
      this.http
        .get(
          '/api/office_web/judge_trade_day/?date=' + value
        )
        .then(res => {
          if (res.data.status_code === '20000') {
            if (res.data.trade_day === 1) {
              this.holdRecordByDate(value)
            } else {
              alert(`${value}非交易日无交易数据，显示最近交易日数据！`)
              this.holdRecord()
            }
          } else {
            this.tableStatus = 'error:连接错误'
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-30 {
  margin-top: 30px;
}

//strategy-info
.strategy-info {
  padding: 20px 34px;

  h1 {
    font-size: 30px;
    font-family: PingFang-SC-Bold;
    // font-weight:bold;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    font-family: FZLTXHK;
    font-weight: 400;
    letter-spacing: 2px;
    color: rgba(102, 102, 102, 1);
    margin-right: 20px;

    span {
      color: rgb(161, 161, 161);
    }
  }

  .info-field > p {
    min-width: 335px;
  }

  .strategy-price {
    font-size: 25px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: rgba(255, 51, 51, 1);
    margin-right: 10px;
  }

  .share-icon {
    img {
      margin: 0 20px;
    }
    img.weixin {
      width: 34px;
      height: 27px;
    }

    img.weibo {
      width: 40px;
      height: 30px;
    }
  }

  .info-right {
    min-width: 200px;
  }

  button {
    width: 140px;
    height: 40px;
    border: 0;
    background: rgba(255, 51, 51, 1);
    color: #fff;
    font-size: 16px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
  }

  button:first-child:hover {
    border: 1px solid #ff3333ff;
    background-color: #fff;
    color: #ff3333ff;
  }
}

.my-card-title {
  height: 87px;
  line-height: 87px;
  font-size: 26px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  padding-left: 30px;
  margin: 0;
}

// transaction show

.transaction-show {
  .strategy-list .chart {
    margin-top: 15px;
    margin-left: 0;
  }
}

.chart-area {
  position: relative;
}

//strategy time
.strategy-time {
  position: absolute;
  left: 0;
  top: 10px;
  margin-left: 20px;

  p {
    font-size: 20px;
    font-family: PingFang-SC-Medium;
    // font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 0;
    margin-right: 30px;
  }

  li {
    list-style: none;
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
    padding: 5px 12px;
    margin-right: 30px;
    color: #999999ff;
    cursor: pointer;
  }

  li.active {
    background: rgba(255, 223, 223, 1);
    color: #ec3b3bff;
  }
}

.tab-card {
  position: relative;

  .date-picker {
    position: absolute;
    top: 0px;
    right: 30px;
  }
}
</style>
